@import "assets/styles/vars";

.reactour__close {
    top: 5px !important;
    right: 5px !important;
    width: 20px !important;
    height: 20px !important;
    color: white !important;
    padding: 6px !important;
    background: $highlight-color !important;
    border-radius: 50% !important;
}

.reactour__helper {
    [data-tour-elem="controls"] {
        button {
            transform : scaleX(-1);
        }
    }
}