@font-face {
  font-family : Greta;
  src         : url('../../fonts/GretaArabic/Greta_Arabic_Regular.eot');
  src         : url('../../fonts/GretaArabic/Greta_Arabic_Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/GretaArabic/Greta_Arabic_Regular.woff') format('woff'),
  url('../../fonts/GretaArabic/Greta_Arabic_Regular.otf') format("opentype");
  font-weight : normal;
}

@font-face {
  font-family : Greta;
  src         : url('../../fonts/GretaArabic/Greta_Arabic_Bold.eot');
  src         : url('../../fonts/GretaArabic/Greta_Arabic_Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/GretaArabic/Greta_Arabic_Bold.woff') format('woff'),
  url('../../fonts/GretaArabic/Greta_Arabic_Bold.otf') format("opentype");
  font-weight : bold;
}

@font-face {
  font-family : "QCF4_QBSML";
  src         : url("../../fonts/QCF/QCF4_QBSML.ttf") format("opentype");
  font-weight : normal;
}


@font-face {
  font-family : "UthmanTN1B";
  src         : url("../../fonts/UthmanTN/UthmanTN1B_Ver10.otf") format("opentype");
  font-weight : normal;
}

@font-face {
  font-family : "Roboto";
  src         : url("../../fonts/Roboto/Roboto-Regular.ttf") format("opentype");
  font-weight : normal;
}

@font-face {
  font-family : "Roboto";
  src         : url("../../fonts/Roboto/Roboto-Bold.ttf") format("opentype");
  font-weight : bold;
}