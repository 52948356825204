:root {
    --aside-width    : calc((2.94vh * 16) + 80px);
    --top-bar-height : 65px;
}

@import "theme/color-palette";

@import "assets/styles/fonts/fonts";

@import "assets/styles/theme/typography";

@import "assets/styles/layout/mq-selectors";

@import "assets/styles/layout/hidden";