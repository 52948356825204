@import "assets/styles/vars";

.Mushaf {

    background-color : rgba($light-color, 0.2);
    padding          : 5px 40px;
    position         : relative;
    height           : 100%;

    @media (min-width : $screen-tablet-max) and (min-height : $screen-phone-max) {
        width : var(--aside-width);
    }


    @media (max-width : $screen-tablet-max) and (orientation : portrait) {
        padding : 5px 0;
    }

    .PagesContainer {

        section {
            direction  : rtl;
            font-size  : 2.93vh;
            width      : 16em;
            min-height : 30.2em;

            @media (min-width : $screen-phone) and (max-width : $screen-tablet-max) and (orientation : portrait) {
                font-size : 5.6vw;
                width     : 100%;
            }

            @media (min-width : $screen-phone) and (max-width : $screen-tablet-max) {
                padding : 0 40px;
            }

            @media (max-width : $screen-phone-max) and (orientation : portrait) {
                padding   : 0 17.5px;
                font-size : 5.7vw;
                width     : 100%;
                height    : 31em;
            }

            @media (max-height : $screen-phone-max) and (orientation : landscape), (max-width : $screen-tablet-max) {
                font-size : 5.6vw;
            }


            > div {
                border      : 1px solid transparent;
                display     : flex;
                flex-flow   : row nowrap;
                text-align  : center;
                row-gap     : 0;
                align-items : center;


                &[datatype=ayah] {
                    > span {
                        margin    : 0;
                        flex-grow : 1;
                        cursor    : pointer;
                    }

                    > span[selected], > span[active] {
                        background-color : #EFE8CF;
                        color            : maroon;

                        @media (min-width : $screen-tablet-max) and (min-height : $screen-phone-max) {
                            background-color : #ECF3EA;
                            color            : #753C37;
                        }

                    }
                }
            }
        }

    }

    footer {

        display        : flex;
        flex-flow      : row nowrap;
        align-items    : center;
        padding-bottom : 40px;

        @media (min-width : $screen-tablet-max) and (min-height : $screen-phone-max) {
            position       : absolute;
            bottom         : -2px;
            width          : calc(100% - 80px);
            padding-bottom : 0;
        }

    }
}


