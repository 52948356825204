@import "mq-breakpoints";

@mixin for-phone-only {
  @media (max-width: $screen-phone-max) { @content; }
}

@mixin for-mini-devices {
  @media (max-width: $mini-devices) { @content; }
}

@mixin for-mini-devices-landscape {
  @media (max-width: $mini-devices) AND (orientation: landscape) { @content; }
}

@mixin for-desktop-devices {
  @media (min-width: $screen-md) { @content; }
}

@mixin for-fhd-devices {
  @media (min-width: $screen-xl) { @content; }
}

@mixin for-qhd-devices {
  @media (min-width: $screen-xxl) { @content; }
}

@mixin for-4k-devices {
  @media (min-width: $screen-4k) { @content; }
}

