html, body {
    margin                  : 0;
    padding                 : 0;
    font-family             : -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing  : antialiased;
    -moz-osx-font-smoothing : grayscale;

    text-align              : start;
    background-color        : #FFFFFF;
    width                   : 100%;
    -webkit-touch-callout   : none; /* iOS Safari */
    -webkit-user-select     : none; /* Safari */
    -moz-user-select        : none; /* Old versions of Firefox */
    -ms-user-select         : none; /* Internet Explorer/Edge */
    user-select             : none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Opera and Firefox */

    @include font-default;


    @include for-qhd-devices {
        font-size : 24px;
    }

    @include for-4k-devices {
        font-size : 36px;
    }


}

html, body {
    height : 100vh;
}

#root {
    height     : 100vh;
    overflow-y : auto;
}

* {
    box-sizing : border-box;
}

code {
    font-family : source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6 {
    @include font-title;
}

h1, h2, h3 {
    margin-top    : 1.25em;
    margin-bottom : 0.6em;
}

h4, h5, h6 {
    margin-top    : 0.6em;
    margin-bottom : 0.6em;
}

textarea:focus,
input:focus,
button:focus {
    outline : none;
}

.no-highlight {
    outline                     : none !important;
    -webkit-tap-highlight-color : transparent;
}

:focus {
    @extend .no-highlight;
}

a,
a:hover,
a:visited,
a:focus {
    text-decoration : none !important;
    color           : $base-color;
    @extend .no-highlight;
}

a:hover {
    color : $highlight-color;
    @extend .no-highlight;
}


input:-webkit-autofill {
    -webkit-box-shadow      : 0 0 0 30px $light-bg inset !important;
    -webkit-text-fill-color : $text-color !important;
}

.context-dark {
    color            : $inv-text-color;
    background-color : $base-color;


    input:-webkit-autofill {
        -webkit-box-shadow      : 0 0 0 30px $base-color inset !important;
        -webkit-text-fill-color : $inv-text-color !important;
    }


    a,
    a:hover,
    a:visited,
    a:focus {
        text-decoration : none !important;
        color           : $highlight-color;
        @extend .no-highlight;
    }

    a:hover {
        color : $bright-color;
        @extend .no-highlight;
    }
}

article {
    font-family : UthmanTN1B, "Segoe UI", sans-serif;
}

main {
    background-color : #FFFFFF;
}