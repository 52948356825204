@import "assets/styles/vars";

.Search {
    position   : relative;
    text-align : center;
    padding    : 3em;

    input[type=text] {
        font-family : "-apple-system", Greta, "Segoe UI", sans-serif;
    }
}

.SearchInput {
    background : rgba(#FFFFFF, 0.9);
    overflow   : hidden;
}

.SearchInputFocused {
    background : #FFFFFF;
}