@import "assets/styles/vars";

body > div {

    font-family : "-apple-system", Greta, "Segoe UI", sans-serif;

    > header {
        background-color    : $dark-color;
        background-size     : contain;
        background-image    : url(/assets/images/bg-1.jpg);
        background-repeat   : no-repeat;
        background-position : top center;
        color               : #FFFFFF;

        > img {
            margin    : auto;
            max-width : 65%;
            display   : block;
            padding   : 1em;
        }

        > nav {
            display     : flex;
            flex-flow   : nowrap;
            align-items : center;
            width       : 100%;
            height      : var(--top-bar-height);
            padding     : 0 2em;
            gap         : 1em;

            a {
                color     : #FFFFFF !important;
                font-size : 1.1rem;

                &:hover {
                    color : $bright-color !important;
                }
            }

            img {
                max-height : calc(var(--top-bar-height) - 35px);
            }

            > aside {
                margin-inline-start : auto;
            }
        }

    }

    > footer {
        font-family      : Roboto, Arial, sans-serif;
        background-color : #181E1E;
        font-size        : small;
        color            : rgba(#FFFFFF, 0.3);
        padding          : 1em;
        text-align       : left;
        border-top       : 10px solid $highlight-color;
        opacity          : 1;

        a {
            color : rgba(#FFFFFF, 0.3) !important;
        }
    }

}