@import "assets/styles/vars";

.ResultItem {
    font-family      : UthmanTN1B, "Segoe UI", sans-serif;
    font-size        : 20px;
    border-radius    : 10px;
    background-color : $light-color;
    margin           : 1em 5em;
    padding          : 1em 2em;

    @media (max-width : $screen-tablet-max) {
        margin  : 0.5em 0 0.5em;
        padding : 0.1rem 0.5em 0.5em;
    }

    h3 {
        font-family : "-apple-system", Greta, "Segoe UI", sans-serif;
        font-weight : normal;
        font-size   : 1.4em;
    }

    p {
        color : $text-color;
    }
}