@import "assets/styles/vars";

.MushafHeader {

    > nav {
        display       : flex;
        align-items   : center;
        align-content : space-between;
        width         : 100%;
        height        : 1.5em;


        @media (min-width : $screen-phone) and (max-width : $screen-tablet-max) {
            padding   : 0 40px;
        }

        @media (max-width : $screen-phone-max) and (orientation : portrait) {
            padding : 0 17.5px;
        }


        button {
            font-family : "-apple-system", Greta, "Segoe UI", sans-serif;
            background  : none;
            border      : none;
            cursor      : pointer;
            padding     : 10px 0;

            &:hover {
                color : $highlight-color;
            }
        }

        .JuzBtn {
            margin-inline-start : auto;
        }

        .SuraBtn {

        }

    }

}