@import "assets/styles/vars";

div.line-sura, div.line-bismillah {
    justify-content : center !important;
}

div.line-sura {
    background      : url(../../../assets/images/SurahTitle-bg-Light.svg) no-repeat;
    background-size : contain;
}

div.line-ayah {
    justify-content : center !important;
    column-gap      : 0;
}

#p1, #p2 {


    div.line-ayah {

        font-size     : 3.7vh;
        align-content : space-around;


        @media (min-width : $screen-phone) and (max-width : $screen-tablet-max) and (orientation : portrait) {
            font-size : 7.6vw;
        }

        @media (max-width : $screen-phone-max) and (orientation : portrait) {
            font-size : 7.7vw;
        }

        @media (max-height : $screen-phone-max) and (orientation : landscape), (max-width : $screen-tablet-max) {
            font-size : 7.6vw;
        }

        > span {
            flex-grow          : 0 !important;
            padding-inline-end : 4px;
        }
    }


}

.font-loading {
    visibility : hidden !important;
    color      : transparent !important;
}
