@import "assets/styles/vars";

.SuraIndex {

    font-family : "-apple-system", Greta, "Segoe UI", sans-serif;

    > section {
        input {
            width       : 100%;
            font-family : UthmanTN1B, "Segoe UI", sans-serif;
            padding     : 10px;
            border      : 1px solid $gray;

        }
    }

    > nav {

        display   : flex;
        flex-flow : nowrap;

        ul {
            padding    : 15px;
            margin     : 0;
            list-style : none;
            height     : 25em;
            overflow-y : scroll;

            &::-webkit-scrollbar {
                width : 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                border-radius : 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background    : $gray-dark;
                border-radius : 10px;
            }

            > li {
                padding : 0;

                em {
                    display           : inline-block;
                    background-color  : $base-color;
                    color             : #FFFFFF;
                    border-radius     : 15px;
                    padding           : 0;
                    margin-inline-end : 20px;
                    width             : 40px;
                    text-align        : center;
                    font-size         : small;
                    font-style        : normal;
                }

                span {
                    white-space : nowrap;
                }

                > a {
                    cursor  : pointer;
                    display : block;
                    padding : 5px 10px;
                }

                > a:hover, a[data-active="1"] {
                    background-color : rgba($highlight-color, 0.95);
                    color            : #FFFFFF;
                    border-radius    : 5px;
                }

            }


        }

    }
}