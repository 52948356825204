.hidden {
    display : none !important;
}

@include for-desktop-devices {
    .hidden-desktop {
        display : none;
    }
}

@include for-mini-devices {
    .hidden-mini {
        display : none;
    }
}

@include for-phone-only {
    .hidden-phone {
        display : none;
    }
}