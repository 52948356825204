@import "../vars";

.sc-text-field {

    .sc-input {
        color : $text-color;

        .context-dark & {
            color : $inv-text-color;
        }
    }

    .sc-underline:before {

        .context-dark & {
            border-bottom-color : $inv-text-color;
        }
    }

    .sc-underline:after {
        .context-dark & {
            border-bottom-color : $bright-color;
        }
    }

    .sc-underline.sc-error:after {
        border-bottom-color : $alert-color !important;
    }

    .sc-error {

        .sc-underline:after {

        }
    }

    .sc-underline:hover:not(tc-disabled):before {
        .context-dark & {
            border-bottom-color : $info-color;
        }
    }

    .sc-label {
        .context-dark & {
            color : $inv-text-color;
        }


        &.sc-focused {
            .context-dark & {
                color : $bright-color !important;
            }
        }

        &.sc-shrink {
            .context-dark & {
                color : $highlight-color !important;
            }
        }

        &.sc-error {
            .context-dark & {
                color : $alert-color !important;
            }
        }

    }

    .sc-helper {
        .context-dark & {
            color: $bright-color;
        }

        &.Mui-error {
            color: $alert-color !important;
        }
    }

    .MuiIconButton-root {
        .context-dark & {
            color : rgba(255, 255, 255, 0.54);
        }
    }

}

.login-form {
    padding-bottom : 1.2em;

    .sc-input {
        padding-bottom : 0.875em;
    }

    .sc-underline:before {
        border-bottom-width : 2px;
    }
}





