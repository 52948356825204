@import "assets/styles/vars";

html, body {
    height : 100%;
}

.Tafsir {

    > main {
        background-color : $light-color;
        box-sizing       : border-box;
        position         : relative;
        flex-flow        : row;

        > aside {
            box-sizing : border-box;
            min-height: calc(100vh - 126px);


            @media (min-width : $screen-tablet-max) and (min-height : $screen-phone-max) {
                position : fixed;
                right    : 0;
                top      : 0;
                bottom   : 0;
            }
        }


    }
}