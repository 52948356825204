@import "assets/styles/vars";

.MushafIndex {

    &, input, button {
        font-family : "-apple-system", Greta, "Segoe UI", sans-serif !important;
    }

    [role="tabpanel"] {
        background-color : rgba($light-color, 0.6);
    }
}