.text-dir {
  text-align : left;
}

[dir="rtl"] .text-dir {
  text-align : right;
}

.text-rtl {
  direction  : rtl;
  text-align : right;
}

.text-rtl-force {
  direction  : rtl !important;
  text-align : right !important;
}

.text-ltr {
  direction  : ltr;
  text-align : left;
}

.text-ltr-force {
  direction  : ltr !important;
  text-align : left !important;
}

.text-end {
  text-align: end;
}

.big {
  font-size : 2.5em;
}
