@import "../vars";

.sc-button {
    .context-dark & {
        color : rgba($inv-text-color, 0.87);
    }

    border-radius : 0 !important;
    box-shadow    : none !important;
}

.sc-outlined {
    .context-dark & {
        border-color : rgba($inv-text-color, 0.23);

        &.MuiButton-outlined.Mui-disabled {
            border : 1px solid rgba($inv-text-color, 0.22) !important;
        }


        &.MuiButton-root.Mui-disabled {
            color : rgba($inv-text-color, 0.36) !important;
        }
    }
}

.sc-context-dark-btn {
    display       : block !important;
    border-radius : 0 !important;
    padding       : 0.5em 2em 0.5em !important;
    margin-top    : 2em !important;
    transition    : 0.35s !important;

    &:hover {
        background-color : rgba($inv-text-color, 0.9) !important;
        color            : $base-color !important;
        border-color     : rgba($inv-text-color, 0.9) !important;
    }

    @include for-phone-only {
        width : 100% !important;
    }
}