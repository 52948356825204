@import "assets/styles/vars";

.QuranIndex {

    nav {

        a {

            display          : flex;
            background-color : $light-color;
            padding          : 0.1rem 0.1rem 0.6rem;
            min-height       : 4rem;
            align-items      : center;
            border-radius    : 5px;
            position         : relative;
            transition       : 0.2s linear;

            @media (min-width : $screen-tablet-max) {
                padding : 1.2rem 0 1.6rem;
            }

            em {
                position          : absolute;
                top               : 36%;
                right             : 1.2rem;
                display           : inline-block;
                background-color  : $base-color;
                color             : #FFFFFF;
                border-radius     : 15px;
                padding           : 0;
                margin-inline-end : 20px;
                width             : 40px;
                text-align        : center;
                font-size         : small;
                font-style        : normal;
            }

            span {
                font-size  : 2rem;
                flex-grow  : 1;
                text-align : center;
            }

            &:hover {
                background-color : #181E1E;
            }

        }
    }

}