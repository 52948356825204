$gray-base         : #191919;
$gray-darkest      : #212121;
$gray-darker       : #434345;
$gray-dark         : #9B9B9B;
$gray              : #D9D9D9;
$gray-light        : #F2F2F2;
$gray-lighter      : #F5F5F5;
$gray-lightest     : #F7F7F7;

$base-color        : #012D20;
$highlight-color   : #CE9A2B;

$dark-color        : #181E1E;
$light-color       : #F6F3E9;
$bright-color      : #FFCD2B;
$selection-color   : #4A83C4; //#f50057;

$alert-color       : #F44336;
$notice-color      : #A53693;
$info-color        : #23C5F0;
$ok-color          : #31E847;
$success-color     : #8FF427;


$light-bg          : #FFFFFF;
$grey-bg           : #E7E8E8;


$text-color        : $gray-darkest;
$lighter-color     : rgba($text-color, 0.7);
$pale-color        : rgba($text-color, 0.5);
$inv-text-color    : #FFFFFF;
$inv-lighter-color : rgba($inv-text-color, 0.7);
$inv-pale-color    : rgba($inv-text-color, 0.5);

