@mixin font-default {
  font-family : "-apple-system", "Roboto", Greta, Arial, serif !important;
}

@mixin font-title {
  font-family : "-apple-system", "Roboto", "Bahij", Arial, Helvetica, sans-serif;
}

@mixin font-heading {
  font-family : "-apple-system", "Roboto Thin", "SwissraLight", Arial, Helvetica, sans-serif;
}

@mixin font-small {
  font-family : "-apple-system", "Roboto Light", "SwissraLight", Arial, serif !important;
}