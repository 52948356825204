@import "assets/styles/vars";

article {

    span.ayah {
        font-family : "UthmanTN1B", "Segoe UI", sans-serif !important;
        color       : $alert-color;
        font-size   : 20px;

        > b {
            font-weight : normal;
            font-size   : 18px;
            color       : $base-color;
            padding     : 0 5px;
        }
    }

}