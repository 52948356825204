@import "assets/styles/vars";


.Article {
    contain                 : content;
    border-top-left-radius  : 20px !important;
    border-top-right-radius : 20px !important;
    overflow                : scroll;
    padding                 : 0 1.5em;
    height                  : 100%;
    width                   : 100vw;
    position                : relative;
    right                   : 0;

    @media (min-width : $screen-tablet-max) and (min-height : $screen-phone-max) {

        position      : fixed;
        right         : var(--aside-width);
        top           : var(--top-bar-height);
        height        : auto;
        bottom        : 0;
        width         : calc(100% - var(--aside-width));
        padding       : 0 4em;
        overflow-x    : hidden;
        overflow-y    : scroll;
        border-radius : 0 !important;

        > button {
            display : none;
        }

    }


    box-sizing              : border-box;
    font-family             : UthmanTN1B, "Segoe UI", sans-serif !important;
    font-size               : 20px;
    line-height             : 2.7em;
    background-color        : $light-color;

    strong {
        font-size   : 1.1em;
        font-weight : normal;
        color       : $selection-color !important;
    }

    &::-webkit-scrollbar {
        width : 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius : 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background    : rgba($highlight-color, 0.6);
        border-radius : 10px;
    }


}

.NavTools {

    padding-bottom : 3em;

    button {

        font-family   : "-apple-system", "Roboto", Greta, Arial, serif;

        label {
            font-family         : UthmanTN1B, "Segoe UI", sans-serif !important;
            display             : inline-block;
            background-color    : rgba($bright-color, 0.2);
            color               : #012D20;
            border-radius       : 15px;
            padding             : 0;
            margin-inline-start : 5px;
            margin-inline-end   : 5px;
            width               : 40px;
            text-align          : center;
            cursor              : pointer;
            font-size           : 1.3em;
        }

    }

}