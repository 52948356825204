@import "assets/styles/vars";

.TopBar {
    height           : var(--top-bar-height);
    overflow         : hidden;
    z-index          : 100;
    box-shadow       : rgba(0, 0, 0, 0.5) 0 5px 5px -5px;
    background-color : $base-color;
    border-bottom    : 2px solid $highlight-color;
    color            : #FFFFFF;
    font-family      : UthmanTN1B, "Segoe UI", sans-serif;
    font-size        : 18px;
    //background       : url("/assets/images/bg-1.jpg") top center no-repeat;

    @media (min-width : $screen-tablet-max) and (min-height : $screen-phone-max) {
        position : fixed;
        width    : calc(100% - var(--aside-width));
        right    : var(--aside-width);
    }


    button {
        font-family : UthmanTN1B, "Segoe UI", sans-serif;
        color       : #FFFFFF;
        font-size   : 19px;

        span {
            padding : 0 10px;
        }


    }

    > nav {
        display       : flex;
        flex-flow     : row nowrap;
        align-items   : center;
        align-content : space-between;

        > img {
            cursor : pointer;
        }

        > button {
            display             : none;

            margin-inline-start : auto;

            @media (min-width : $screen-tablet-max) {
                display : initial;
            }
        }

        > nav {
            margin-inline-start : auto;
            display             : flex;
            flex-flow           : nowrap;
        }
    }
}